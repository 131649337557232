<template lang="pug">
div(v-if='SobreMi')
  //-Pc
  .d-none.d-md-block
    v-container(fluid).pa-0
      v-img(gradient="to top right, transparent , black" width='100%' height='600px' :src='SobreMi.imagen' )
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12
        v-row.mt-12.ml-12.mb-12
          div.rectangulo_titulo.secundario.mr-4.mt-4
          h1(v-html='SobreMi.texto' style='font-size: 2em' v-scrollanimation).white--text
    .bloque-sobre-mi
      .texto-resumen(v-scrollanimation)
        p(v-html='Inicio.sobreMi.resumen').black--text


  //-Móvil
  .d-md-none
    v-container(fluid).pa-0
      v-img( gradient="to top right, rgba(0, 0, 0,.8) ,rgba(69, 64, 64 ,.28)" :src='SobreMi.imagen' aspect-ratio=1.9)
        v-row.align-center.justify-center        
          v-col(md=11)
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.align-start.justify-start.mt-5.ml-4(v-scrollanimation) 
                div.rectangulo_titulo_movil.primario.mt-4.mr-2
                h2.white--text.mt-4 SOBRE MÍ
      v-row.justify-center.align-center(style='padding-bottom: 10%; padding-top: 10%').mr-4.ml-4
        p(v-html='SobreMi.resumen' v-scrollanimation).black--text


    
</template>
<script>
  export default {
    data: () => ({
      show: false,
    }),
  }
</script>
<style lang="sass" scoped>
.card
	height: 380px
.fila-texto-movil
	position: absolute
	display: flex
	top: 3em
	left: 2em
	font-size: 14px
	img
		position: absolute
		display: flex
		width: 30px
		height: 30px
		top: 4rem
		left: -1em
.medidamovil
	padding-bottom: 10%

.fila-texto
	position: absolute
	display: flex
	top: 80%
	left: 10%
	img
		position: absolute
		display: flex
		width: 80px
		height: 70px
		top: 90%
		left: -14%
				
</style>

<style lang="sass" scoped>
  .rectangulo_titulo
    width: 14px
    height: 73px
  .rectangulo_titulo_movil
    width: 10px
    height: 40px
  .divider_inicial
    max-height: 10px!important
    height: 9px!important
</style>

<style lang="sass" scoped>
.bloque-sobre-mi
  height: 530px
  .texto-titulo
    position: absolute
    top: 10%
    left: 43%
    text-align: center
  .texto-resumen
    padding: 0 15% 0 15%
    position: absolute
    top: 22%
    text-align: center
    p
      font-size: 20px
.before-enter
  opacity: 0
  transform: translatex(-100%)
  transition: all 1.5s ease-out   
.enter
  opacity: 1
  transform: translateX(0px)
</style>